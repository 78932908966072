import { fg } from '@confluence/feature-gating';

/**
 This function is used to determine if the starring button should appear for a specific content type.
 Folders don't have a view yet, but we would want to support them.
*/
export const useIsStarringEnabled = (
	contentType: string | null | undefined,
	contentMode?: string,
) => {
	if (fg('confluence_frontend_object_header')) {
		// Starring currently doesn't support unpublished drafts (which could be of contentType page or blogpost)
		if (contentMode === 'draft') {
			return false;
		}
		return (
			contentType === 'page' ||
			contentType === 'blogpost' ||
			contentType === 'whiteboard' ||
			contentType === 'database' ||
			contentType === 'embed' ||
			contentType === 'folder'
		);
	} else {
		return (
			contentType === 'whiteboard' ||
			contentType === 'database' ||
			contentType === 'embed' ||
			contentType === 'folder'
		);
	}
};
