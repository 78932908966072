import { fg } from '@confluence/feature-gating';

/**
 This function is used in the Content Types header to determine if the Watch dialog should
 appear for a specific content type (i.e whiteboards/databases and any future content types)
 */
export const useIsWatchDialogEnabled = (contentType: string | null | undefined): boolean => {
	return (
		contentType === 'whiteboard' ||
		(contentType === 'database' && fg('confluence_frontend_database_inline_comments'))
	);
};
