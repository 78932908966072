import type { FC } from 'react';
import React, { useContext, useCallback } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';

import { fg } from '@confluence/feature-gating';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { START_TOUCH } from '@confluence/navdex';
import { useRouteActions } from '@confluence/route-manager';
import { FeatureCode, generatePathWithFeatureCodesQueryParam } from '@confluence/feature-codes';

import { PageCardContext } from '../PageCardContext';
import { usePageCardAnalyticsEvents } from '../hooks';
import { isSpacePageCard } from '../transformers';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageLinkComponent = styled.a<PageLinkType>({
	position: 'absolute',
	height: '100%',
	width: '100%',
	top: 0,
	left: 0,
	color: 'transparent',
	zIndex: 0,
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderRadius: (p) => (p.compact ? 'unset' : token('border.radius.200', '6px')),
		zIndex: 1,
	},
});

type PageLinkType = {
	compact?: boolean;
	shouldHideFolder?: boolean;
	featureAttributionCode?: FeatureCode;
};

export const PageLink: FC<PageLinkType> = ({
	compact,
	shouldHideFolder,
	featureAttributionCode,
}) => {
	const context = useContext(PageCardContext);
	const {
		id,
		type,
		status,
		title: { text },
		links: { webui },
	} = context;
	const { createAnalyticsEvent } = usePageCardAnalyticsEvents();
	const { push } = useRouteActions();

	const shouldUseFeatureAttribution =
		(featureAttributionCode === FeatureCode.PAGE_TREE_CARD &&
			fg('confluence_frontend_page_tree_feature_attribution')) ||
		(featureAttributionCode === FeatureCode.EOP_REC &&
			fg('confluence_frontend_insights_feature_attribution'));

	const fireAnalyticsEvent = useCallback(
		(action: string, includeNavdex: boolean) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action,
					actionSubject: isSpacePageCard(context) ? 'spacePageCard' : 'globalPageCard',
					attributes: {
						...(includeNavdex && {
							navdexPointType: START_TOUCH,
						}),
						contentType: type,
						contentId: id,
						contentStatus: status ?? null, // status/contentStatus is used to distinguish published page vs draft
					},
				},
			}).fire();
		},
		[context, createAnalyticsEvent, id, type, status],
	);

	const handlePageCardClick = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			fireAnalyticsEvent('clicked', true);
			if (shouldUseFeatureAttribution) {
				e.preventDefault();
				if (e.metaKey || e.ctrlKey) {
					window.open(
						generatePathWithFeatureCodesQueryParam(`${CONTEXT_PATH}${webui}`, [
							featureAttributionCode,
						]),
					);
				} else {
					push(
						generatePathWithFeatureCodesQueryParam(`${CONTEXT_PATH}${webui}`, [
							featureAttributionCode,
						]),
					);
				}
			}
		},
		[featureAttributionCode, fireAnalyticsEvent, push, shouldUseFeatureAttribution, webui],
	);

	const handlePageCardHover = useCallback(
		(e: any) => {
			e.stopPropagation();
			e.preventDefault();
			fireAnalyticsEvent('hovered', false);
		},
		[fireAnalyticsEvent],
	);

	// in the content tree, folders are buttons that expand/collapse the folder, and not links
	const shouldRenderLink = !shouldHideFolder || type !== 'folder';

	return shouldRenderLink ? (
		<PageLinkComponent
			href={`${CONTEXT_PATH}${webui}`}
			onClick={handlePageCardClick}
			onMouseEnter={handlePageCardHover}
			compact={compact}
		>
			<VisuallyHidden>{text}</VisuallyHidden>
		</PageLinkComponent>
	) : null;
};
