import type { FC } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import { styled } from '@compiled/react';

import { useSessionData } from '@confluence/session-data';
import { useIsStarringEnabled, useIsWatchDialogEnabled } from '@confluence/content-types-utils';

import { PageCardContext } from '../../PageCardContext';
import type { Content } from '../../card-types';

import { EditButton } from './EditButton';
import { StarButton } from './StarButton';
import { WatchButton } from './WatchButton';
import { CopyLinkButton } from './CopyLinkButton';

export const QuickActionsContext = createContext({
	hidden: true,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QuickActionsContainer = styled.ul<{ compact?: boolean }>({
	margin: 0,
	padding: 0,
	listStyle: 'none',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: (props) => (props.compact ? 'flex-end' : 'unset'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QuickActionsListItem = styled.li({
	marginTop: '0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderAction = styled.li<{ compact?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: `${(props) => (props.compact ? 24 : 32)}px`,
	width: '32px',
	marginTop: '0',
});

type Action = 'edit' | 'copy' | 'watch' | 'star';
const defaultActions: Action[] = ['edit', 'copy', 'watch', 'star'];

const useEnabledActions = () => {
	const isStarringEnabledForDatabases = useIsStarringEnabled('database');
	const isWatchingWhiteboardsEnabled = useIsWatchDialogEnabled('whiteboard');

	const { isLicensed } = useSessionData();
	const { status, type, subType } = useContext(PageCardContext);

	if (!isLicensed || status === 'draft') {
		return [];
	}

	const contentActions: Action[] = ['star'];
	const watchAction: Action[] = ['watch'];

	const livePageActions = defaultActions.filter((item) => item !== 'edit');

	const typeActionMap: Record<Content, Record<string, Action[]>> = {
		page: {
			none: defaultActions,
			live: livePageActions,
		},
		blogpost: {
			none: defaultActions,
		},
		whiteboard: {
			none: [...contentActions, ...(isWatchingWhiteboardsEnabled ? watchAction : [])],
		},
		database: {
			none: isStarringEnabledForDatabases ? contentActions : [],
		},
		embed: {
			none: contentActions,
		},
		folder: {
			none: contentActions,
		},
	};

	return typeActionMap[type]?.[subType ?? 'none'] || [];
};

type QuickActionsProps = {
	actions?: Action[];
	hidden?: boolean;
	compact?: boolean;
	children?: React.ReactNode;
};

export const QuickActions: FC<QuickActionsProps> = ({
	actions = defaultActions,
	hidden,
	compact,
}) => {
	const enabledActions = useEnabledActions();
	const numberOfPlaceholders = defaultActions.length - enabledActions.length;

	const quickActionsContextValue = useMemo(() => ({ hidden: Boolean(hidden) }), [hidden]);

	return (
		<QuickActionsContext.Provider value={quickActionsContextValue}>
			<QuickActionsContainer compact={compact}>
				{Array(numberOfPlaceholders).fill(<PlaceholderAction compact={compact} />)}
				{actions.map((action) => {
					let actionButton: JSX.Element | null = null;
					switch (enabledActions.includes(action) ? action : null) {
						case 'edit':
							actionButton = <EditButton key={action} />;
							break;
						case 'copy':
							actionButton = <CopyLinkButton key={action} />;
							break;
						case 'watch':
							actionButton = <WatchButton key={action} />;
							break;
						case 'star':
							actionButton = <StarButton key={action} />;
							break;
						default:
							actionButton = null;
					}
					return actionButton ? (
						<QuickActionsListItem key={action}>{actionButton}</QuickActionsListItem>
					) : null;
				})}
			</QuickActionsContainer>
		</QuickActionsContext.Provider>
	);
};
