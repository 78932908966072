import type { FC } from 'react';
import React, { useContext } from 'react';

import { CopyLinkButton as CopyContentLinkButton } from '@confluence/copy-content-link';

import { PageCardContext } from '../../PageCardContext';

import { ActionContainer } from './ActionContainer';

export const CopyLinkButton: FC<{}> = (_) => {
	const { id: contentId, type: contentType, space, analyticsData } = useContext(PageCardContext);

	return (
		<ActionContainer>
			<CopyContentLinkButton
				contentId={contentId}
				contentType={contentType}
				spaceKey={space?.spaceKey ?? ''}
				source={analyticsData?.source ?? ''}
				buttonAppearance="subtle"
				tooltipPosition="top"
			/>
		</ActionContainer>
	);
};
