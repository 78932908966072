import type { FC } from 'react';
import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import LikeIcon from '@atlaskit/icon/core/thumbs-up';
import CommentIcon from '@atlaskit/icon/core/comment';
import { Box, Inline, Flex, xcss } from '@atlaskit/primitives';

import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { PageCardContext } from '../PageCardContext';

const i18n = defineMessages({
	likes: {
		id: 'page-card.social.likes',
		defaultMessage: 'likes',
		description: 'Icon to show how many people like a page.',
	},
	comments: {
		id: 'page-card.social.comments',
		defaultMessage: 'comments',
		description: 'Icons to show how many people commented on a page.',
	},
});

const socialProofContainerStylesNav3 = xcss({
	color: 'color.text.subtle',
});

const socialProofContainerStyles = xcss({
	color: 'color.text.subtlest',
});

const separatorStyles = xcss({
	marginLeft: 'space.075',
});

const countStylesNav3 = xcss({
	font: 'font.heading.xxsmall',
	marginLeft: 'space.025',
});

const countStyles = xcss({
	font: 'font.body.small',
	marginLeft: 'space.025',
	fontWeight: 'font.weight.regular',
});

type SocialProofProps = {
	isInlineAction?: boolean;
};

export const SocialProof: FC<SocialProofProps> = ({ isInlineAction = false }) => {
	const { likesCount, commentsCount } = useContext(PageCardContext);
	const intl = useIntl();
	const isNav4Enabled = useIsNav4Enabled();

	return (
		<Flex
			alignItems="center"
			xcss={isNav4Enabled ? socialProofContainerStyles : socialProofContainerStylesNav3}
			{...(isNav4Enabled ? { columnGap: 'space.150' } : { columnGap: 'space.075' })}
		>
			{isInlineAction && (!!likesCount || !!commentsCount) && <Box xcss={separatorStyles}>•</Box>}
			{likesCount ? (
				<Flex alignItems="center" {...(isNav4Enabled && { columnGap: 'space.025' })}>
					<LikeIcon label={intl.formatMessage(i18n.likes)} />
					<Inline xcss={isNav4Enabled ? countStyles : countStylesNav3}>{likesCount}</Inline>
				</Flex>
			) : null}
			{commentsCount ? (
				<Flex alignItems="center" {...(isNav4Enabled && { columnGap: 'space.025' })}>
					<CommentIcon label={intl.formatMessage(i18n.comments)} />
					<Inline xcss={isNav4Enabled ? countStyles : countStylesNav3}>{commentsCount}</Inline>
				</Flex>
			) : null}
		</Flex>
	);
};
